import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/cele-sprzedazowe-crm-questy.jpg";
import Lightbox from "../components/lightBox";
import ResponsiveEmbed from "react-responsive-embed";
import { Link } from "gatsby";
import { LiteYouTubeEmbed } from "react-lite-youtube-embed";
import * as styles from "./index.module.css";


export const query = graphql`
  {
    cele3: file(relativePath: { eq: "cele-smart-crm-questy.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    cele1: file(relativePath: { eq: "moje-cele-kierownik.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    cele2: file(relativePath: { eq: "cele-zdarzenia.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const Celesmart = ({ data }) => {
  return (
    <Artykul
      title="Cele sprzedażowe w CRM - jak je określać"
      articleImage={obrazekArtykulu}
      articleImageAlt="cele sprzedażowe"
      metaTitle="Cele sprzedażowe w CRM - jak je określać"
      metaDescription="Czym są cele sprzedażowe? ✅ Jak precyzyjnie wyznaczać cele sprzedażowe? ✅ Metoda SMART - co to? ✅ Jak metoda SMART pomaga wyznaczać cele sprzedażowe? ✅ System CRM a cele sprzedażowe - jak nimi zarządzać?"
    >
      <h3
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Jak metoda SMART pomaga określić cele sprzedażowe?
      </h3>
      <br />
      <p>
        Określanie celów sprzedażowych to twardy orzech do zgryzienia dla
        niejednego szefa. No bo{" "}
        <strong>jak sprawić, by cel nie był ani za mały, ani za duży? </strong>{" "}
        Co zrobić, żeby był dla handlowców motywacją, a nie powodem do drgawek
        na samą myśl o nim? Jak wyznaczać plan, który sprawi, że firma nadal
        będzie się rozwijać? Odpowiedzi znajdziesz w tym odcinku, zapraszamy!
      </p>

      <div className="text-block">
        <br />
        <LiteYouTubeEmbed
          poster="maxresdefault"
          id="rfOKAeNxzho"
          title="Sklep B2B: jak zacząć sprzedawać w Internecie hurtowo"
        />
      </div>

      <p>
        Niestety wielu menedżerów traktuje temat celu sprzedażowego w sposób
        bardzo schematyczny, jakby istniał prosty wzór matematyczny, który
        rozwiązuje ten temat raz na zawsze. Otóż nie istnieje. Wyjaśniamy
        dlaczego!
      </p>

      <h3
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Dlaczego warto wyznaczać cele sprzedażowe
      </h3>
      <p>
        Istnieje wiele powodów, dla których warto wyznaczać cele sprzedażowe. Po
        pierwsze, dzięki nim,{" "}
        <strong>
          zarząd firmy będzie wiedział jakich wyników może oczekiwać w danym
          okresie
        </strong>
        . Wyniki finansowe zazwyczaj przedstawiane są w perspektywie kwartału,
        więc tego się będziemy trzymać w tym artykule. Po drugie, odpowiednio
        wyznaczone cele sprawiają, że{" "}
        <strong>handlowcy wiedzą do jakiego rezultatu dążą</strong>, nie błądzą
        we mgle. To sprawia, że obniża się ich poziom stresu, dlatego mogą
        wykonywać swoje obowiązki wydajniej. Po trzecie,{" "}
        <strong>cele ułatwiają planowanie budżetu marketingowego</strong>.
        Wypracowując je wspólnie z działem marketingu możesz osiągać perfekcyjny
        balans pomiędzy planem sprzedażowym i marketingowym. To pozwoli uniknąć
        zbędnych korekt w ustalonym budżecie reklamowym. Praktyka wskazuje na
        to, że samo rozpoczęcie w firmie wyznaczania celów może skutkować
        podniesieniem jej efektywności o ponad 20%.
      </p>
<section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz trafnie określać cele sprzedażowe?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i trafnie określać cele sprzedażowe
            </Link>
          </div>
          </div>
    </section>
      <h3
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Jakie czynniki wpływają na wyznaczanie celów sprzedażowych?
      </h3>
      <p>
        <strong>Najważniejsza jest strategia przedsiębiorstwa</strong>. To od
        niej zawsze powinno wychodzić precyzowanie celów sprzedażowych. Zatem
        które z jej elementów należy wziąć pod uwagę?
      </p>
      <p>
        <ul>
          <li>segmenty i grupy odbiorców usług/produktów Twojej firmy,</li>
          <li>długo i krótkoterminowe plany rozwojowe,</li>
          <li>
            problemy, na jakie odpowiada Twoja firma poprzez swoje
            produkty/usługi
          </li>
        </ul>
      </p>

      <p>
        Kolejnym, bardzo istotnym czynnikiem, który wpływa na formułowanie celów
        sprzedażowych są ludzie, a&nbsp;konkretniej handlowcy, których masz na
        pokładzie. To oni będą wykonywali założone plany, więc muszą być
        dokładnie dostosowane do ich możliwości, kompetencji i doświadczenia.
        Menedżer sprzedaży powinien zadbać o odpowiednie szkolenia i motywację.{" "}
        <strong>
          Realistycznie dostosowane cele sprawią, że handlowcy zamiast stresować
          się wysokością planu, będą zmotywowani i pełni zapału do jego
          realizacji
        </strong>
        . Przecież każdy lubi spełniać cele, prawda? Nie zapominajmy jednak przy
        tym o ambicji.
      </p>

      <h3
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        O czym musisz pamiętać, wyznaczając cele sprzedażowe?
      </h3>
      <p>
        <strong>
          Cele sprzedażowe warto zsynchronizować z pracą działu marketingu
        </strong>
        . Planujesz dodatkowe akcje reklamowe? Cel sprzedażowy musi to
        uwzględniać. Najgorszym możliwym scenariuszem jest sytuacja,
        w&nbsp;której wygenerowane kontakty do klientów zainteresowanych Twoimi
        produktami lub usługami będą „wisieć w&nbsp;próżni” ze względu na brak
        dodatkowych rąk do pracy w momencie największego obciążenia. O takiej
        synergii warto pomyśleć także w kontekście innych działów firmy (np.
        dostarczania czy logistyki).
      </p>

      <p>
        Co jeszcze powinieneś wziąć pod uwagę, wyznaczając cele sprzedażowe?{" "}
        <strong>Sezon, na jaki przypadają</strong>. Twoje produkty lepiej
        sprzedają się w wakacje, czy zimą? Jeśli okres letni to czas, kiedy Twoi
        klienci raczej niechętnie robią u Ciebie zakupy, albo nie korzystają
        wtedy z Twoich usług to koniecznie weź to pod uwagę, określając cele
        sprzedażowe. Ich zbyt wysoki poziom w „sezonie ogórkowym” może
        doprowadzić do wypalenia się handlowców. Postaw im wyższy cel, gdy
        potencjalnie zamówienia spływać mogą ze wszystkich stron.
      </p>
      <h3
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Cele sprzedażowe z metodą SMART
      </h3>
      <p>
        Z pomocą przy określaniu celów przychodzi na szczęście metoda SMART. To
        akronim pochodzący od pięciu angielskich wyrazów Specific, Measurable,
        Achievable, Relevant oraz Time-bound. To sposób na uniknięcie tworzenia
        losowych celów w Twojej firmie.
      </p>
      <p>
        <ul>
          <li>
            <strong>Precyzyjne i konkretnie sformułowane</strong>, czyli takie,
            które nie pozostawiają cienia wątpliwości,
          </li>
          <li>
            <strong>Mierzalne</strong>, czyli oparte na danych,
          </li>
          <li>
            <strong>Ambitne</strong>, czyli z pewnym zapasem do którego należy
            dążyć,
          </li>
          <li>
            <strong>Osiągalne i realne</strong>, czyli takie, które przy wzięciu
            pod uwagę czynników z punktu 3. są możliwe do spełnienia,
          </li>
          <li>
            <strong>Określone w czasie</strong>.
          </li>
        </ul>
      </p>

      <p>
        Jak może wyglądać przykładowy cel zapisany przy użyciu metody SMART?
      </p>
      <p>Zły przykład „Zwiększenie zysków do końca roku”</p>
      <p>Zły przykład „Sprzedaż 100 rowerów marki Y”</p>
      <p>
        Dobry przykład „Od 1 marca 2021 roku do 30 czerwca 2021(T, R) roku nasza
        firma zrealizuje 3500 (M, A) zamówień na rowery szosowe firmy X (S)”
      </p>
      <p>
        Dobry przykład „Wzrost sprzedaży wszystkich bagażników rowerowych o 20%
        w marcu 2021 w stosunku do poprzedniego miesiąca”.
      </p>

      <h3
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Cele sprzedażowe w CRM
      </h3>
      <p>
        System CRM to narzędzie o ogromnych możliwościach w kontekście
        planowania i koordynacji sprzedaży. Jedną z funkcji, która z pewnością
        wspomoże Twoją firmę w tym obszarze jest wyznaczanie celów
        sprzedażowych. W prosty sposób można dodawać plany dla kierowników i
        handlowców. Zarówno dla wielu, jak i pojedynczych pracowników.
        Intuicyjnie określisz rodzaj celu oraz jego ramy czasowe. Raz ustawiony
        cel możesz bez ingerencji monitorować aż do wyznaczonego końca. Cele
        sprzedażowe w systemie CRM wyznaczane są oczywiście przy pomocy metody
        SMART.
      </p>

      <Lightbox
        style={{
          maxWidth: 700,
          margin: "0 auto",
        }}
        images={[data.cele3]}
        alts={["cele sprzedażowe - widget w systemie CRM"]}
      />

      <p>
        System CRM pozwala tworzyć kilka rodzajów celów. Pierwszym z nich są{" "}
        <strong>
          cele sprzedażowe oparte na wartości sprzedaży w wyznaczonym okresie
        </strong>
        . Widget celu umieszczony na pulpicie automatycznie pobiera dane z
        faktur i wyświetla łączną kwotę, na jaką dany handlowiec je wystawił.
        Dzięki temu nie tracisz czasu na ręczne sprawdzanie wyników sprzedawcy.
      </p>

      <Lightbox
        style={{
          maxWidth: 700,
          margin: "0 auto",
        }}
        images={[data.cele1]}
        alts={["cele sprzedażowe - sprzedaż wygenerowana przez handlowca"]}
      />

      <p>
        Drugim typem są <strong>cele określane na podstawie zdarzeń</strong>.
        Jakiego typu? Może to być liczba spotkań z&nbsp;klientami, albo liczba
        kontaktów z nimi (mailowe czy telefoniczne). W systemie CRM można
        tworzyć własne typy zdarzeń, dlatego cel może być zawsze dopasowany do
        procesów Twojej firmy.
      </p>

      <Lightbox
        style={{
          maxWidth: 700,
          margin: "0 auto",
        }}
        images={[data.cele2]}
        alts={["cele sprzedażowe - zdarzenia zarejestrowane w systemie CRM"]}
      />

      <p>
        Nasze rozwiązanie CRM, jako nieliczne na rynku, może pochwalić się opcją
        celów niestandardowych. Co to znaczy?{" "}
        <strong>
          Możemy przygotować dla Twojej firmy cel oparty na dowolnych danych
          gromadzonych w&nbsp;systemie
        </strong>
        . Chcesz określić cel sprzedażowy na nowy produkt? Żaden problem.
        Zapraszam do kontaktu z&nbsp;nami, opowiemy Ci jak może to wyglądać w
        praktyce.
      </p>

      <h3
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Podsumowanie
      </h3>
      <p>
        Wyznaczanie celów sprzedażowych to najważniejszy czynnik rozwoju każdej
        firmy. Na pewno kojarzysz powiedzenie „Ten, kto się nie rozwija, stoi w
        miejscu”. Wyznaczając cele sprzedażowe pamiętaj o metodzie SMART i o
        szeregu czynników, o których opowiedziałem w tym odcinku.
        <strong>
          {" "}
          Odpowiednie wykorzystanie zasobów i precyzyjne określanie potrzeb to
          podstawa dobrego celu.{" "}
        </strong>
        Z nimi Twoja firma z łatwością będzie przekraczać kolejne targety, a
        wizja przyszłości będzie jasna i klarowna. Ma to pozytywny wpływ nie
        tylko na saldo konta firmowego, ale także na motywację pracowników, bo
        każdy z nich wie do czego zmierza.
      </p>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz trafnie określać cele sprzedażowe?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i trafnie określać cele sprzedażowe
            </Link>
          </div>
          </div>
    </section>
    </Artykul>
  );
};

export default Celesmart;
